import {
  FETCH_ACTIVE_TAGS,
  FETCH_EXTERNAL_TAGS,
  FETCH_CONSUMER_NOTES,
  SET_NOTES_DATES_RANGE,
  SET_SHOW_PER_PAGE,
  SHOW_DATEPICKER,
  HIDE_DATEPICKER,
} from "../types";

import { showLoader, hideLoader } from "./app";

import {
  getActiveTags,
  getExternalTags,
  getAllNotes
} from "../../lib/api/admin"

export function fetchActiveTags() {
  return async (dispatch) => {
    const data = await getActiveTags();
    if (data.success === "True") {
      dispatch({ type: FETCH_ACTIVE_TAGS, payload: data.data });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

export function fetchExternalTags() {
  return async (dispatch) => {
    const data = await getExternalTags();
    if (data.success === "True") {
      dispatch({ type: FETCH_EXTERNAL_TAGS, payload: data.data });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

export function fetchConsumerNotes(request, consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const data = await getAllNotes({ client_id: consumerId, ...request });
    if (data.success === "True") {
      dispatch({ type: FETCH_CONSUMER_NOTES, payload: data.data });
      dispatch(fetchActiveTags());
      dispatch(fetchExternalTags());
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
    dispatch(hideLoader());
  };
}

export function setNotesDatesRange(dates) {
  return { type: SET_NOTES_DATES_RANGE, payload: dates };
}

export function setShowPerPage(pages) {
  return { type: SET_SHOW_PER_PAGE, payload: pages };
}

export function showDatepicker() {
  return { type: SHOW_DATEPICKER };
}

export function hideDatepicker() {
  return { type: HIDE_DATEPICKER };
}