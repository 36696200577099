import sendRequest from "./sendRequest";
import { 
  paymentMethodAddedCardDataLayer,
  paymentSuccessfulDataLayer,
  paymentMethodAddedACHDataLayer
} from "../helpers/dataLayers";

export const getDocuSignEnvelopUrl = (refNumber) => {
  return sendRequest(`/client/envelope-url/${refNumber}`, "GET");
}

export const getDocuSignEnvelopStatus = (refNumber) => {
  return sendRequest(`/client/envelope-details/${refNumber}`, "GET");
}

export const sendEsingLetter = (body) => {
  return sendRequest(`/client/electronic-signature-authorization/send`, "POST", body);
}

export const verifyEsingLetter = (body) => {
  return sendRequest(`/client/electronic-signature-authorization/verify`, "POST", body);
}

export const getContactPreference = (id) => {
  if (id) {
    return sendRequest(`/client/contact-preference/${id}`, "GET");
  } else {
    return sendRequest(`/client/contact-preference`, "GET");
  }
}

export const putContactPreference = (body, id) => {
  if (id) {
    return sendRequest(`/client/contact-preference/${id}`, "POST", body);
  } else {
    return sendRequest(`/client/contact-preference`, "POST", body);
  }
}

export const createDispute = (body) => {
  return sendRequest(`/disputes/create`, "POST", body);
}

export const getDisputes = () => {
  return sendRequest(`/disputes/all`, "GET");
}

export const getThirdPartyContact = (id) => {
  return sendRequest(`/client/third-party-contact/${id}`, "GET");
}

export const getCommCount = (debt_id, clientId) => {
  if (debt_id === "all") {
    return sendRequest(`/client/comm-count/${clientId}/${debt_id}`, "GET");
  } else {
    return sendRequest(`/client/comm-count/${debt_id}`, "GET");
  }
}

export const addThirdPartyContact = (body, id) => {
  return sendRequest(`/client/third-party-contact/${id}`, "POST", body);
}

export const deleteThirdPartyContact = (body, id) => {
  return sendRequest(`/client/third-party-contact/${id}`, "DELETE", body);
}

export const editThirdPartyContact = (body, id) => {
  return sendRequest(`/client/third-party-contact/${id}`, "PUT", body);
}

export const getConsumerDebts = (status, body) => {
  return sendRequest(`/client/debts?status=${status}`, "POST", body);
};

export const getConsumerDebts_new = (body) => {
  return sendRequest(`/client/debts1`, "POST", body);
};

export const registerNewTkl2Account = (body) => {
  return sendRequest('/client/register-new-tkl2-account', "POST", body);
}

export const getShortConsumerDebts_new = (body) => {
  return sendRequest(`/client/debts1?reduced=True`, "POST", body);
}

export const getEmailTemplate = (id,body) => {
  return sendRequest(`/emails/${id}/templates`, "POST", body);
}

export const sendEmailTemplate = (body) => {
  return sendRequest(`/emails/send`, "POST", body);
}

export const getPaymentsInfo = (clientId, paymentId) => {
  return sendRequest(`/client/payments/${clientId}/${paymentId}`, "GET");
}

export const putPaymentsInfo = (clientId, body) => {
  return sendRequest(`/client/payments/${clientId}`, "PUT", body);
}

export const deletePaymentPromise = (clientId, loanId) => {
  return sendRequest(`/client/promises/${clientId}/${loanId}`, "DELETE");
}

export const getPlanInfo = (body) => {
  return sendRequest(`/client/plan`, "POST", body);
};

// Example: { client_id: "", debt_id: }
export const getConsumerDebtHistory = (body) => {
  return sendRequest(`/client/payment-history`, "POST", body);
};

// Example: { client_id, debt_id }
export const getConsumerDebtSchedule = (body) => {
  return sendRequest(`/client/payment-schedule`, "POST", body);
};
// New History
export const getNewPaymentHistory = (debt, body) => {
  return sendRequest(`/client/payment-history1/${debt.debt_id}`, "GET", body);
}
// admin history
export const getNewAdminPaymentHistory = (info, body) => {
  return sendRequest(`/client/payment-history1/${info.client_id}/${info.debt_id}`, "GET", body);
}
export const getNewPaymentSchedule = (debt, body) => {
  return sendRequest(`/client/payment-schedule1/${debt.debt_id}`, "GET", body);
}
// admin schedule
export const getNewAdminPaymentSchedule = (info, body) => {
  return sendRequest(`/client/payment-schedule1/${info.client_id}/${info.debt_id}`, "GET", body);
}
// End New History
export const getSavedPayPal = (body) => {
  return sendRequest(`/client/saved-paypal-accounts`, "POST", body);
};

export const getSavedCards = (body) => {
  return sendRequest(`/client/saved-cards`, "POST", body);
};

export const getSavedBankAccounts = (body) => {
  return sendRequest(`/client/saved-bank-accounts`, "POST", body);
};

export const getDefaultPaymentMethod = (body) => {
  return sendRequest(`/client/get-default-payment-method`, "POST", body);
};

// Forgot password flow
// Example: { "email": "someemail@mail.org" }
export const sendVerificationCode = (body) => {
  return sendRequest(`/user/send-verification-code`, "POST", body);
};

// Example: { "email": "someemail@mail.org", "verification_code": "0744" }
export const verifyUser = (body) => {
  return sendRequest(`/user/verify-user`, "POST", body);
};

// Example: { "email": "someemail@mail.org", "verification_code": "0744", "new_password": "123", "new_password_confirmation": "123" }
export const setNewPassword = (body) => {
  return sendRequest(`/user/set-new-password`, "POST", body);
};

// PAYMENTS
export const formPaymentIframe = (body) => {
  body.local = window.location.port === "3000" ? true : false;
  return sendRequest(`/client/card-iframe-url`, "POST", body);
};

export const formACHIframe = (body) => {
  return sendRequest(`/client/ach-iframe-url`, "POST", body);
};

export const addPaymentCard = (body) => {
  paymentMethodAddedCardDataLayer();
  return sendRequest(`/client/add-card`, "POST", body);
};

export const addBankAccount = (body) => {
  paymentMethodAddedACHDataLayer();
  return sendRequest(`/client/add-bank-account`, "POST", body);
};

// Example: { “card_id”: “", “client_id”: “", “debt_id”: """, “payment_method”: “ach”, “plan_id”: 153, “start_day”: 1 }
export const submitPayment = (body) => {
  paymentSuccessfulDataLayer(body.debt_id);
  return sendRequest(`/client/submit-payment`, "POST", body);
};

export const submitOneTimePayment = (body) => {
  paymentSuccessfulDataLayer(body.debt_id);
  return sendRequest(`/client/submit-one-time-payment`, "POST", body);
};

// Example: { "amount": "1032.02", "client_id": "", "debt_id": "", "payment_method": "ach", "card_id": "546d099b-6326-41f0-933c-819ff4db6fdf" }
export const submitExtraPayment = (body) => {
  paymentSuccessfulDataLayer(body.debt_id);
  return sendRequest(`/client/submit-extra-payment`, "POST", body);
};

export const removePaymentMethod = (body) => {
  return sendRequest(`/client/delete-payment-method`, "POST", body);
};

export const setDefaultPaymentMethod = (body) => {
  return sendRequest(`/client/set-default-payment-method`, "POST", body);
};

// Example: { "client_id": "b09e9a13-7065-4a15-9832-1fa0a1c3d84a", "debt_id": 2214, "pay_amount": "100", "pay_date": "11-11-2020", "transaction_type": "payment_plan or otp" }
export const getReceiptTemplate = (body) => {
  return sendRequest(`/receipt-template`, "POST", body);
};

// export const getConsumerProfile = (body) => {
//   return sendRequest(`/client/self`, "POST", body);
// };

export const getConsumerProfile = (body) => {
  if (body.client_id) {
    return sendRequest(`/client/self/${body.client_id}`, "GET");
  } else {
    return sendRequest(`/client/self`, "GET");
  }
};

// QUERY URLs FOR CONSUMER
export const getConsumerDataFromUrl = (query) => {
  return sendRequest(`/client/login?id=${query}`, "POST");
};

// Login by url 
export const getLoginByUrl = (queryToBody) => {
  return sendRequest(`/client/login?login_id`, "POST", queryToBody);
};

// NACHA
/* 
  Example: 
  { 
    "client_id": "9fd8513f-be87-4caa-ac6d-8f451008a288", 
    "payment_date": "2021-06-18T08:07:51.100000Z", 
    "payment_frequency": "One Time" 
  } 
*/
export const getNACHA = (body) => {
  return sendRequest(`/client/nacha`, "POST", body);
};

/* 
    Example: 
    { 
      "client_id": "9fd8513f-be87-4caa-ac6d-8f451008a288",
      "account_number": "acctno 8887867",
      "account_type": "1", 
      "agreement_timestamp": "2001-06-15T12:07:31+03:00", 
      "email": "python@gmail.com", 
      "loan_id": "100500", 
      "name_on_check": "Johnnie Kid", 
      "routing_number": "988888882" 
      "bank_name": "bank name"
    }
*/
export const generateNACHA = (body) => {
  return sendRequest(`/client/nacha`, "PUT", body);
};

// Logout (set is_verified in Database to false)
export const logOutClient = () => {
  return sendRequest(`/client/unverify`, "POST");
};

export const logOutUser = () => {
  return sendRequest(`/user/unverify`, "POST");
};

export const getTenantSettings = () => {
  return sendRequest("/tenant/settings", "GET");
};