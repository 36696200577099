const hostname = window.location.hostname.toLowerCase();

export const getTenant = () => {
  switch (hostname) {
    case process.env.REACT_APP_INVESTINET_STAGE:
      return "investinet";
    case process.env.REACT_APP_INVESTINET_PROD:
      return "investinet";
    case process.env.REACT_APP_CONSUEGRA_STAGE:
      return "consuegra";
    case process.env.REACT_APP_CONSUEGRA_PROD:
      return "consuegra";
    case process.env.REACT_APP_DEMO_STAGE:
      return "investinet";
    case process.env.REACT_APP_DEMO_PROD:
      return "investinet";
    case process.env.REACT_APP_TROMBERG_STAGE:
      return "tromberg";
    case process.env.REACT_APP_TROMBERG_PROD:
      return "tromberg";
    case process.env.REACT_APP_NCLS_STAGE:
      return "ncls";
    case process.env.REACT_APP_NCLS_PROD:
      return "ncls";
    case process.env.REACT_APP_TIDEWATER_STAGE:
      return "tidewater";
    case process.env.REACT_APP_TIDEWATER_PROD:
      return "tidewater";
  }
};

export const tenant = {
  INVESTINET: "investinet",
  CONSUEGRA: "consuegra",
  TROMBERG: "tromberg",
  NCLS: "ncls",
  TIDEWATER: "tidewater",
};
