import {
  paymentMethodAddedACHDataLayer,
} from "../helpers/dataLayers";
import sendRequest from "./sendRequest";

let hostname = window.location.hostname.toLowerCase();
const appPort = process.env.REACT_APP_BACKEND_PORT;
const appApi = process.env.REACT_APP_BACKEND_API;
const appPrefix = process.env.REACT_APP_BACKEND_API_PREFIX;
const appProtocol = process.env.REACT_APP_BACKEND_API_PROTOCOL;

// for Redesign url call defauld api
if (hostname === process.env.REACT_APP_DEMO_STAGE) {
  hostname = process.env.REACT_APP_INVESTINET_STAGE;
}

// remove after API domain changed
if (hostname === process.env.REACT_APP_INVESTINET_PROD) {
  hostname = "waythru.investinet.com";
}

// CONSUMER LOGIN
export const consumerLogin = async (body) => {
  body = JSON.stringify(body);
  const headers = {};
  headers["Content-Type"] = "application/json";
  const loginData = await fetch(
    `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}/client/login`,
    {
      method: "POST",
      body,
      headers,
    }
  );

  const data = await loginData.json();

  return data;
};


export const addBankAccount = (body) => {
  paymentMethodAddedACHDataLayer();
  return sendRequest(`/client/add-bank-account`, "POST", body);
};

export const getDebtInfo = (body) => {
  return sendRequest(`/client/debt`, "POST", body);
};

export const patchProfile = (body) => {
  return sendRequest(`/client/self`, "PATCH", body);
}

export const updateClientProfile = async (body) => {
  if ((body.email || body.email === "") && !body.client_id) {
    const response = await sendRequest(`/client/self`, "PUT", body);
    if (response.success === "True") {
      const token = response.token;

      const role = JSON.parse(localStorage.getItem("userData")).role.toString();

      localStorage.setItem(
        "userData",
        JSON.stringify({
          token,
          role,
        })
      );
    }

    return response;
  } else {
    if (body.client_id) {
      return sendRequest(`/client/self/${body.client_id}`, "PUT", body);
    } else {
      return sendRequest(`/client/self`, "PUT", body);
    }
  }
};

export const updateClientEmployer = (body) => {
  return sendRequest(`/client/update-employer`, "PUT", body);
};

export const getConsumerGoogleAccount = (client_id) => {
  const preparedRequest = {
    client_id: client_id,
  };
  return sendRequest(`/client/google-account`, "POST", preparedRequest);
};

export const deleteConsumerGoogleAccount = (client_id) => {
  const preparedRequest = {
    client_id: client_id,
  };
  return sendRequest(`/client/google-account`, "DELETE", preparedRequest);
};

export const connectConsumerGoogleAccount = (request) => {
  return sendRequest(`/client/google-account1`, "POST", request);
};

export const getTESTConsumerGoogleAccount = (client_id) => {
  const preparedRequest = {
    client_id: client_id,
  };
  return sendRequest(`/client/google-account1`, "GET", preparedRequest);
};

export const getPossiblePaymentSchedule = (request) => {
  return sendRequest(`/client/possible-payment-schedule`, "POST", request);
};

export const getContentBlock = async (name) => {
  return sendRequest(`/content-blocks/${name}`, "GET");
};
