import moment from "moment";
import { formattedMoney } from "lib/helpers/formatters";

export const NACHAWebMultiModalData = (
  companyName,
  phone,
  hours,
  payment_frequency,
  type,
  bank_name,
  name_on_check,
  routing_number,
  account_number,
  isChangingPaymentMethod,
  start_date,
  end_date,
  start_amount,
  end_amount,
  timestamp
) => {
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              ${
                !isChangingPaymentMethod
                  ? `
                      <p>I hereby authorize ${companyName} to initiate ${payment_frequency} electronic debit withdrawals from the indicated bank account for payments in the amount of $${formattedMoney(
                      start_amount
                    )} (or as they become due and payable under the terms and conditions of the agreement) and as described herein. Furthermore, I assert that I am the owner, or an authorized signer of the bank account provided.</p>
                      <p>
                        The first payment will be withdrawn from your bank on ${moment(
                          start_date
                        ).format("MM/DD/YYYY")} or (the following business
                        banking day), then, subsequent ${payment_frequency} payments for the duration of this authorization and
                        term of your payment plan.
                      </p>
                      <p>The final payment of $${formattedMoney(
                        end_amount
                      )} will be due on ${moment(end_date).format(
                      "MM/DD/YYYY"
                    )}.</p>`
                  : `
                      <p>
                        I hereby authorize ${companyName}, to initiate an electronic debit
                        withdrawal from my account with the Financial Institution. Furthermore, I assert that I am the
                        owner or an authorized signer of this bank account.
                      </p>
                    `
              }
              ${
                type
                  ? `
                  <p>Type of Account: ${
                    type === "1" ? "Checking" : "Savings"
                  }</p>
                  <p>Financial Institution Name: ${bank_name}</p>
                  <p>Name on Account: ${name_on_check}</p>
                  <p>Transit/ABA Number: ${routing_number}</p>
                  <p>Last 4 of Account Number: ${account_number}</p>
                `
                  : `
                  <p>Type of Account: Debit Card</p> 
                  <p>Name on Account: ${name_on_check}</p>
                `
              }
              <p>To rescind this authorization and suspend or cancel this payment, or the amount withdrawn from your account needs to be different than the amount authorized herein, you may call 1 ${phone} during the following business hours: ${hours}.</p>
              <p>Authorization Signature: ${name_on_check}</p>
              <p>Date and Time: ${
                timestamp
                  ? moment(timestamp).format("ll")
                  : moment(Date()).format("ll")
              }</p>
            </div>
          </div>`;
};

export const NACHAWebSingleModalData = (
  companyName,
  phone,
  hours,
  type,
  bank_name,
  name_on_check,
  routing_number,
  account_number,
  payment_amount,
  nearestScheduleDate,
  timestamp
) => {
  const sum = localStorage.getItem("paymentSum");
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              ${
                !sum && !payment_amount
                  ? `
                  <p>
                    I hereby authorize ${companyName} to initiate an electronic debit
                    withdrawal from my account with the Financial Institution. Furthermore, I assert that I am the
                    owner or an authorized signer of this bank account.
                  </p>
                  <p>Payment date will show as: ${moment(
                    nearestScheduleDate
                  ).format("ll")}</p>
                `
                  : `
                  <p>
                    I hereby authorize ${companyName} to initiate an electronic debit
                    withdrawal from my account with the Financial Institution indicated
                    below in the amount of $${
                      sum ? formattedMoney(sum) : formattedMoney(payment_amount)
                    }. 
                    Furthermore, I assert that I am the owner or an authorized signer of this bank account.
                  </p>
                  <p>Payment date will show as: ${moment(
                    nearestScheduleDate
                  ).format("ll")}</p>
                `
              }
              ${
                type
                  ? `
                  <p>Type of Account: ${
                    type === "1" ? "Checking" : "Savings"
                  }</p>
                  <p>Financial Institution Name: ${bank_name}</p>
                  <p>Name on Account: ${name_on_check}</p>
                  <p>Transit/ABA Number: ${routing_number}</p>
                  <p>Last 4 of Account Number: ${account_number}</p>
                `
                  : `
                  <p>Type of Account: Debit Card</p> 
                  <p>Name on Account: ${name_on_check}</p>
                `
              }
              <p>
                To rescind this authorization and suspend or cancel this payment, or the amount
                withdrawn from your account needs to be different than the amount
                authorized herein, you may call 1 ${phone} during the
                following business hours: ${hours}.
              </p>
              <p>Authorization Signature: ${name_on_check}</p>
              <p>Date and Time: ${
                timestamp
                  ? moment(timestamp).format("ll")
                  : moment(Date()).format("ll")
              }</p>
            </div>
          </div>`;
};

export const NACHAPhoneMultiModalData = (
  companyName,
  phone,
  hours,
  start_date,
  end_date,
  name_on_check,
  type,
  account_number,
  isChangingPaymentMethod,
  consumerState,
  numberOfPayments,
  payment_frequency,
  start_amount,
  end_amount,
  timestamp
) => {
  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const dayOfTheWeek = () => {
    if (payment_frequency === "Weekly") {
      return `${moment(start_date).format("dddd")} each week`;
    }
    if (payment_frequency === "Bi-Weekly") {
      return `${moment(start_date).format("dddd")} every other week`;
    }
    if (payment_frequency === "Monthly" || payment_frequency === "One Time") {
      return `${ordinal_suffix_of(
        moment(start_date).format("D")
      )} day of the month`;
    }
  };

  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              <div class="nacha-context-text">
                <p>As of today’s date, ${
                  timestamp
                    ? moment(timestamp).format("MM/DD/YYYY")
                    : moment(Date()).format("MM/DD/YYYY")
                }, you have 
                  given verbal authorization to debit your account. Please confirm your understanding with "I agree".
                </p>
                <p>
                  ${
                    !isChangingPaymentMethod
                      ? `<span>
                      To confirm the payment details, ${numberOfPayments}  ${
                          numberOfPayments > 1 ? `payments` : `payment`
                        } of
                      $${formattedMoney(
                        start_amount
                      )} scheduled on the ${dayOfTheWeek()} beginning on ${moment(
                          start_date
                        ).format("MM/DD/YYYY")} and 
                      ending on ${moment(end_date).format(
                        "MM/DD/YYYY"
                      )} will be debited
                      for ${name_on_check}${!type ? "." : " "}
                      ${
                        type
                          ? `from the ${
                              type === "1" ? "Checking" : "Savings"
                            } account 
                        number ending in ${account_number}. `
                          : ""
                      }
                      The final payment of $${formattedMoney(
                        end_amount
                      )} will be due on ${moment(end_date).format(
                          "MM/DD/YYYY"
                        )}.
                    </span>`
                      : ""
                  } 
                </p>
                <p>
                  You will receive a confirmation notice with the terms of your payments and confirming your authorization.
                </p>
              ${
                consumerState === "Rhode Island" ||
                consumerState === "Massachusetts"
                  ? `<p>
                  You are giving us verbal authorization to establish postdated payments on your account. 
                  InvestiNet does not require you to set up postdated payments and you have the option to use 
                  other methods to submit payments if you do not wish to schedule postdated payments.
                </p>`
                  : ""
              }
              <p>
                If you have any questions or concerns, or would like to revoke this authorization, please give us a call at 1 ${phone} between ${hours}. 
                I need to record your authorization to initiate the payment.
              </p>
              <p>${name_on_check}, do you authorize ${companyName} to initiate this payment in accordance with the terms I just stated and are you willing to authorize the payment over the phone instead of through paper mail?</p>
              </div>
            </div>
          </div>`;
};

export const NACHAPhoneSingleModalData = (
  companyName,
  phone,
  hours,
  name_on_check,
  type,
  account_number,
  paymentAmount,
  consumerState,
  startDate,
  timestamp
) => {
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              <p>
                As of today’s date, ${
                  timestamp
                    ? moment(timestamp).format("MM/DD/YYYY")
                    : moment(Date()).format("MM/DD/YYYY")
                }, you have 
                given verbal authorization to debit your account. Please confirm your understanding with "I agree".
              </p>
              <p>
                To confirm the payment details, the payment of $${formattedMoney(
                  paymentAmount
                )} will be debited on or after 
                ${
                  startDate
                    ? moment(startDate).format("MM/DD/YYYY")
                    : moment(Date()).format("MM/DD/YYYY")
                } for ${name_on_check}${!type ? "." : " "}
                ${
                  type
                    ? `from the ${
                        type === "1" ? "Checking" : "Savings"
                      } account number ending in ${account_number}.`
                    : ""
                }
              </p>
              <p>
                You will receive a confirmation notice with the terms of your payment and confirming your authorization.
              </p>
              ${
                consumerState === "Rhode Island" ||
                consumerState === "Massachusetts"
                  ? `<p>
                  You are giving us verbal authorization to establish a postdated payment 
                  on your account. InvestiNet does not require you to set up postdated payments 
                  and you have the option to use other methods to submit payments 
                  if you do not wish to schedule a postdated payment.
                </p>`
                  : ""
              }
              <p>
                If you have any questions or concerns, or would like to revoke this authorization, please give us a call at 1 ${phone} between ${hours}. 
                I need to record your authorization to initiate the payment.
              </p>
            
              <p>${name_on_check}, do you authorize ${companyName} to initiate this payment in accordance with the terms I just stated and are you willing to authorize the payment over the phone instead of through paper mail?</p>
            </div>
          </div>`;
};

// export const NACHACardModalData = (
//   start_date,
//   name_on_check,
//   planInfo,
//   paymentAmount,
//   id,
//   consumerState
// ) => {
//   const sum = localStorage.getItem("paymentSum");

//   const renderAmount = () => {
//     if (sum) {
//       return formattedMoney(sum);
//     } if (paymentAmount) {
//       return formattedMoney(paymentAmount);
//     } if (planInfo?.per_month) {
//       return formattedMoney(planInfo?.per_month);
//     }
//   }

//   if (!sum && !paymentAmount && !planInfo?.per_month) {
//     return `<div class="template template--nacha">
//       <h2 class="heading heading-2">Authorization Form</h2>
//       <div class="nacha-context-text">
//         <p>
//           As of today’s date, ${moment(Date()).format("MM/DD/YYYY")}, you have
//           given verbal authorization to debit your account. Please confirm your understanding with "I agree".
//         </p>
//         <p>
//           You will receive a confirmation letter with the terms of your payment and confirming
//           your authorization.
//         </p>

//         ${id && (consumerState === "Rhode Island" || consumerState === "Massachusetts") ? (
//           `<p>
//             You are giving us verbal authorization to establish postdated payments on your account.
//             InvestiNet does not require you to set up postdated payments and you have the option to use
//             other methods to submit payments if you do not wish to schedule postdated payments.
//           </p>`
//         ) : ""}

//         <p>
//           If you have any questions or concerns, or would like to revoke this authorization, please give us a call please call at 1 ${multitenancyPhone()} between ${multitenancyHours()}.
//           I need to record your authorization to initiate the payment.
//         </p>

//         <p>${name_on_check}, do you authorize ${multitenancyCompanyName()} to initiate this payment in accordance with the terms I just stated.</p>
//       </div>
//     </div>`;
//   } else {
//     return `<div class="template template--nacha">
//       <h2 class="heading heading-2">Authorization Form</h2>
//       <div class="nacha-context-text">
//         <p>
//           As of today’s date, ${moment(Date()).format("MM/DD/YYYY")}, you have
//           given verbal authorization to debit your account. Please confirm your understanding with "I agree".
//         </p>

//         <p>
//           To confirm the payment details, the payment of $${renderAmount()} will be debited on or after
//           ${moment(start_date).format("MM/DD/YYYY") || moment(Date()).format("MM/DD/YYYY")} for ${name_on_check}.
//         </p>
//         <p>
//           You will receive a confirmation letter with the terms of your payment and confirming
//           your authorization.
//         </p>

//         ${id && paymentAmount && (consumerState === "Rhode Island" || consumerState === "Massachusetts") ? (
//           `<p>
//             You are giving us verbal authorization to establish a postdated payment
//             on your account. InvestiNet does not require you to set up postdated payments
//             and you have the option to use other methods to submit payments
//             if you do not wish to schedule a postdated payment.
//           </p>`
//         ) : ""}

//         ${id && !paymentAmount && (consumerState === "Rhode Island" || consumerState === "Massachusetts") ? (
//           `<p>
//             You are giving us verbal authorization to establish postdated payments on your account.
//             InvestiNet does not require you to set up postdated payments and you have the option to use
//             other methods to submit payments if you do not wish to schedule postdated payments.
//           </p>`
//         ) : ""}

//         <p>
//           If you have any questions or concerns, or would like to revoke this authorization, please give us a call please call at 1 ${multitenancyPhone()} between ${multitenancyHours()}.
//           I need to record your authorization to initiate the payment.
//         </p>

//         <p>${name_on_check}, do you authorize ${multitenancyCompanyName()} to initiate this payment in accordance with the terms I just stated.</p>
//       </div>
//     </div>`;
//     }
// };

export const OLD_NACHAWebMultiModalData = (
  companyName,
  phone,
  hours,
  payment_frequency,
  per_month,
  start_date,
  type,
  bank_name,
  name_on_check,
  routing_number,
  account_number,
  isChangingPaymentMethod,
  dateValue
) => {
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              ${
                !isChangingPaymentMethod
                  ? `
                      <p>I hereby authorize ${companyName} to initiate ${payment_frequency} electronic debit withdrawals from the indicated bank account for payments in the amount of $${formattedMoney(
                      per_month
                    )} (or as they become due and payable under the terms and conditions of the agreement) and as described herein.</p>
                      <p>
                        The first payment will be withdrawn from your bank on ${moment(
                          start_date || dateValue
                        ).format("ll")} or (the following business
                        banking day), then, subsequent ${payment_frequency} payments for the duration of this authorization and
                        term of your payment plan.
                      </p>`
                  : `
                      <p>
                        I hereby authorize ${companyName} to initiate an electronic debit
                        withdrawal from my account with the Financial Institution indicated below. Furthermore, I assert that I am the
                        owner or an authorized signer of this bank account.
                      </p>
                    `
              }
              <p>Type of Account: ${type === "1" ? "Checking" : "Savings"}</p>
              <p>Financial Institution Name: ${bank_name}</p>
              <p>Name on Account: ${name_on_check}</p>
              <p>Transit/ABA Number: ${routing_number}</p>
              <p>Last 4 of Account Number: ${account_number}</p>
              <p>If I wish to rescind this authorization and cancel this payment, or the amount withdrawn from my account is different than the amount authorized herein, you may call 1 ${phone} during the following business hours: ${hours}.</p>
              <p>Furthermore, I assert that I am the owner, or an authorized signer of the bank account provided.</p>
              <p>Authorization Signature: ${name_on_check}</p>
              <p>Date and Time: ${moment(Date()).format("ll")}</p>
            </div>
          </div>`;
};

export const OLD_NACHAWebSingleModalData = (
  companyName,
  phone,
  hours,
  type,
  bank_name,
  name_on_check,
  routing_number,
  account_number,
  payment_amount
) => {
  const sum = localStorage.getItem("paymentSum");
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              ${
                sum || payment_amount
                  ? `
                  <p>
                    I hereby authorize ${companyName} to initiate an electronic debit
                    withdrawal from my account with the Financial Institution indicated below in the amount of $${
                      sum ? formattedMoney(sum) : formattedMoney(payment_amount)
                    }. 
                    Furthermore, I assert that I am the owner or an authorized signer of this bank account.
                  </p>`
                  : `
                  <p>
                    I hereby authorize ${companyName} to initiate an electronic debit
                    withdrawal from my account with the Financial Institution indicated below. Furthermore, I assert that I am the
                    owner or an authorized signer of this bank account.
                  </p>`
              }
              <p>Payment date will show as: ${moment(Date()).format("ll")}</p>
              <p>Type of Account: ${type === "1" ? "Checking" : "Savings"}</p>
              <p>Financial Institution Name: ${bank_name}</p>
              <p>Name on Account: ${name_on_check}</p>
              <p>Transit/ABA Number: ${routing_number}</p>
              <p>Last 4 of Account Number: ${account_number}</p>
              <p>
                To rescind this authorization and cancel payment, or the amount
                withdrawn from your account needs to be different than the amount
                authorized herein, you may call 1 ${phone} during the
                following business hours: ${hours}.
              </p>
              <p>Authorization Signature: ${name_on_check}</p>
              <p>Date and Time: ${moment(Date()).format("ll")}</p>
            </div>
          </div>`;
};

export const OLD_NACHAPhoneMultiModalData = (
  companyName,
  phone,
  hours,
  start_date,
  per_month,
  name_on_check,
  type,
  account_number,
  isChangingPaymentMethod,
  consumerState
) => {
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              <p>As of today’s date, ${moment(Date()).format("ll")}, you have given verbal authorization to debit your account. ${
                !isChangingPaymentMethod
                  ? `<span>The payments will start on ${moment(start_date).format(
                      "ll"
                    )}, in the amount of $${formattedMoney(
                      per_month
                    )} for ${name_on_check}, from ${
                      type === "1" ? "Checking" : "Savings"
                    } account with account number ending in ${account_number}.</span>`
                  : ""
              } 
            ${
              consumerState === "Rhode Island" ||
              consumerState === "Massachusetts"
                ? `<p>
                You are giving us verbal authorization to establish postdated payments on your account. 
                ${companyName} does not require you to set up postdated payments and you have the option to use 
                other methods to submit payments if you do not wish to schedule postdated payments.
              </p>`
                : ""
            }
            <p>If you have any questions or concerns, please call 1 ${phone} between ${hours}. If you would like to revoke this authorization, please give us a call at 1 ${phone}.</p>
            <p>Please confirm your understanding with “I agree”.</p>
          </div>
        </div>`;
};

export const OLD_NACHAPhoneSingleModalData = (
  companyName,
  phone,
  hours,
  name_on_check,
  type,
  account_number,
  paymentAmount,
  consumerState
) => {
  return `<div class="template template--nacha">
            <h2 class="heading heading-2">ACH Authorization Form</h2>
            <div class="nacha-context-text">
              <p>
                As of today’s date, ${moment(Date()).format("ll")}, you have given verbal authorization to debit your account. The payment of $${formattedMoney(
                  paymentAmount
                )} will be dated on 
                ${moment(Date()).format("ll")} for ${name_on_check}, from the 
                ${
                  type === "1" ? "Checking" : "Savings"
                } account number ending in ${account_number}. 
              </p>
              ${
                consumerState === "Rhode Island" ||
                consumerState === "Massachusetts"
                  ? `<p>
                  You are giving us verbal authorization to establish a postdated payment 
                  on your account. ${companyName} does not require you to set up postdated payments 
                  and you have the option to use other methods to submit payments 
                  if you do not wish to schedule a postdated payment.
                </p>`
                  : ""
              }
              <p>If you have any questions or concerns, please call 1 ${phone} between ${hours}. If you would like to revoke this authorization, please give us a call at 1 ${phone}.</p>
            <p>Please confirm your understanding with “I agree”.</p>
            </div>
          </div>`;
};