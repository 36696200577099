import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { ButtonLink } from "components/ui-kit/ButtonLink";
import { formattedMoney } from "lib/helpers/formatters";

export const AuthAgreementWebOnePay = ({
  accountType,
  setIsShowingModal,
  selectedPaymentAccount,
  paymentAmount,
  nearestScheduleDate,
}) => {
  const sum = localStorage.getItem("paymentSum");
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );

  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name
  );
  const phoneNumber = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours);

  const accountNumberLastFour = () => {
    if (selectedPaymentAccount === "new") {
      return accountPaymentRequest.account_number?.length <= 4
        ? accountPaymentRequest.account_number
        : accountPaymentRequest.account_number?.slice(
            accountPaymentRequest.account_number?.length - 4,
            accountPaymentRequest.account_number?.length
          );
    } else {
      return selectedPaymentAccount.AccountNumber?.length <= 4
        ? selectedPaymentAccount.AccountNumber
        : selectedPaymentAccount.AccountNumber?.slice(
            selectedPaymentAccount.AccountNumber?.length - 4,
            selectedPaymentAccount.AccountNumber?.length
          );
    }
  };

  return (
    <div className="nacha-container">
      <div className="nacha-text">
        {!sum && !paymentAmount ? (
          <>
            <p>
              I hereby authorize {companyName} to initiate an electronic debit
              withdrawal from my account with the Financial Institution.
              Furthermore, I assert that I am the owner or an authorized signer
              of this bank account.
            </p>
            <p>
              Payment date will show as:{" "}
              <span className="bold">
                {moment(nearestScheduleDate).format("ll")}
              </span>
            </p>
          </>
        ) : (
          <>
            <p>
              I hereby authorize {companyName} to initiate an electronic debit
              withdrawal from my account with the Financial Institution
              indicated below in the amount of $
              {sum ? formattedMoney(sum) : formattedMoney(paymentAmount)}.
              Furthermore, I assert that I am the owner or an authorized signer
              of this bank account.
            </p>
            <p>
              Payment date will show as:{" "}
              <span className="bold">
                {moment(nearestScheduleDate).format("ll")}
              </span>
            </p>
          </>
        )}
        {accountType ? (
          <>
            <p>
              Type of Account: {accountType === "1" ? "Checking" : "Savings"}
            </p>
            <p>
              Financial Institution Name:{" "}
              <span className="bold">
                {accountPaymentRequest?.bank_name ||
                  selectedPaymentAccount.BankName}
              </span>
            </p>
            <p>
              Name on Account:{" "}
              <span className="bold">
                {accountPaymentRequest?.name_on_check}
              </span>
            </p>
            <p>
              Transit/ABA Number:{" "}
              <span className="bold">
                {accountPaymentRequest?.routing_number ||
                  selectedPaymentAccount.RoutingNumber}
              </span>
            </p>
            <p>
              Last 4 of Account Number:{" "}
              <span className="bold">{accountNumberLastFour()}</span>
            </p>
          </>
        ) : (
          <>
            <p>Type of Account: Debit Card</p>
            <p>
              Name on Account:{" "}
              <span className="bold">
                {accountPaymentRequest?.name_on_check}
              </span>
            </p>
          </>
        )}
        <p>
          To rescind this authorization and suspend or cancel this payment, or
          the amount withdrawn from your account needs to be different than the
          amount authorized herein, you may call 1 {phoneNumber} during the
          following business hours: {hours}.
        </p>
        <p>
          Authorization Signature:{" "}
          <span className="bold">{accountPaymentRequest?.name_on_check}</span>
        </p>
        <p>
          Date and Time:{" "}
          <span className="bold">{moment(Date()).format("ll")}</span>
        </p>
      </div>
      <ButtonLink
        text="Download NACHA Authorization Agreement"
        onClickHandler={() => setIsShowingModal(true)}
      />
    </div>
  );
};

AuthAgreementWebOnePay.propTypes = {
  accountType: PropTypes.string,
  selectedPaymentAccount: PropTypes.any,
  setIsShowingModal: PropTypes.func,
};
